import * as React from "react"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"
import { Seo } from "../../components/seo"
import { Card, Col, Row, Typography } from "antd"
import PageContent from "../../components/page-content"
import { Link } from "gatsby"
const { Title } = Typography

const LearnPage = (data) => {
  if (!data) return null

  return (
    <Layout>
      <Seo title={"Learn"} />
      <PageContent>
        <Title>Learn</Title>
        {/* <h1>Feed Chart</h1> */}
        <Row gutter={[24, 24]}>
          <Col span={24} lg={12}>
            <Card
              title="FAQ"
              actions={[
                <Link key="faq" to="/learn/faq">
                  Read questions
                </Link>,
              ]}
            />
          </Col>
          <Col span={24} lg={12}>
            <Card
              title="Videos"
              actions={[
                <Link key="video" to="/learn/videos">
                  Watch videos
                </Link>,
              ]}
            />
          </Col>
        </Row>
      </PageContent>
    </Layout>
  )
}

export default LearnPage
